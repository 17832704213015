<template>
  <form @submit.prevent="handleUpdate">
    <div class="p-4 lg:p-0 mt-4">
      <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Links and Social Media <is-required /></label>
      <div class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-4 flex justify-between items-center mb-2">
        <input
          v-model="details.website"
          type="url"
          class="block w-full border-0 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder="https://domain.ext"
        />

        <!-- <select for="dropzone-file" class="border-light-grey bg-[#fafafa] px-6 sm:px-4 py-2 text-brand-black rounded-lg block cursor-pointer">
          <option value="website">Website</option>
          <option value="linkedin">Linkedin</option>
          <option value="facebook">Facebook</option>
          <option value="twitter">Twitter</option>
        </select> -->
      </div>
      <div class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-4 flex justify-between items-center mb-2">
        <input
          v-model="details.linkedin"
          type="url"
          class="block w-full border-0 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder="https://linkedin.com/in/username"
        />
      </div>
      <div class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-4 flex justify-between items-center mb-2">
        <input
          v-model="details.facebook"
          type="url"
          class="block w-full border-0 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder="https://facebook.com/username"
        />
      </div>
      <div class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-4 flex justify-between items-center">
        <input
          v-model="details.twitter"
          type="url"
          class="block w-full border-0 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder="https://twitter.com/username"
        />
      </div>
    </div>

    <!-- <div class="mt-3">
      <div class="flex items-center cursor-pointer add-btn" @click.prevent="addFieldSocial()">
        <img src="@/assets/icons/plus2.svg" class="mr-2" />
        <p class="text-[#FE0D73] ml-1 leading-[160%] text-[14px]" @click="field + 1">Add more links</p>
      </div>
    </div> -->

    <div class="mt-6 p-4 lg:p-0">
      <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Media Publications</label>
      <div class="flex">
        <input
          v-model="details.publications[0].publication"
          type="url"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-4 px-4 focus:outline-none outline-none"
          placeholder="Any articles you would like to point out"
        />
        <!--          Remove Svg Icon-->
      </div>

      <template v-if="details.publications.length > 1">
        <div v-for="(item, index) in details.publications.slice(1)" :key="index" class="flex items-center">
          <input
            v-model="item.publication"
            type="url"
            class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-4 px-4 focus:outline-none outline-none mt-4"
            placeholder="Any articles you would like to point out"
          />
          <!--          Remove Svg Icon-->
          <svg
            v-show="details.publications.length > 1"
            @click.prevent="removeField(index, details.publications)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="ml-2 cursor-pointer mt-3"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="#E91868"
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            />
          </svg>
        </div>
      </template>
    </div>

    <div class="mt-3">
      <div class="flex items-center cursor-pointer add-btn" @click.prevent="addField()">
        <img src="@/assets/icons/plus2.svg" class="mr-2" />
        <p class="text-[#FE0D73] ml-1 leading-[160%] text-[14px]">Add more links</p>
      </div>
    </div>

    <div class="w-full mt-6 grid grid-cols-2 gap-4">
      <button
        type="button"
        @click="goBack"
        class="w-full bg-white border-brand-black border text-brand-black p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ button_prev }}
      </button>
      <button
        type="submit"
        :disabled="checkDisability"
        class="w-full bg-brand-black text-white p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ loading ? 'Loading...' : button_action }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';

const store = useStore();
const details = ref({
  website: '',
  linkedin: '',
  facebook: '',
  twitter: '',
  publications: [{ publication: '' }],
});
const pageLoaded = ref(false);
const disabled = ref(false);
const loading = ref(false);
const field = ref(1);

const props = defineProps({
  task: { type: String, default: 'onboard' },
});
const userDetails = computed(async () => await store.getters['auth/userDetails']);
const button_action = computed(() => (props.task === 'onboard' ? 'Continue' : 'Save'));
const button_prev = computed(() => (props.task == 'onboard' ? 'Back' : ''));
const userForm = computed(async () => await store.getters['global/getForm']);

const emit = defineEmits(['changeTab', 'goPrev']);

const checkDisability = computed(() => {
  return details.value.website || details.value.linkedin !== '' ? (disabled.value = false) : (disabled.value = true);
});

const addField = () => {
  try {
    details.value.publications.push({
      publication: '',
      placeholder: 'Any articles you would like to point out',
    });
  } catch (error) {
    console.log(error);
  }
};

const addFieldSocial = () => {
  //add socials
};

const removeField = (index, fieldName) => {
  fieldName.splice(index, 1);
};

const goBack = () => {
  emit('goPrev');
};

async function handleUpdate() {
  try {
    disabled.value = true;
    loading.value = true;

    if (props.task === 'onboard') {
      await store.commit('global/setForm', { ...userForm.value, ...details.value });
      emit('changeTab');
    } else {
      const user = await store.getters['auth/userDetails'];
      const formData = { ...user.employer, ...details.value };
      const { message } = await store.dispatch('auth/updateUserProfile', formData);
      alert(message, 'success');
      store.dispatch('auth/getUser', { forceReload: true });
    }
  } catch (error) {
    disabled.value = false;
    loading.value = false;
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

onMounted(async () => {
  const user = await store.getters['auth/userDetails'];
  const formData = { ...user.employer, ...userForm.value };
  details.value = {
    website: formData.website,
    linkedin: formData.linkedin || formData.social_links?.linkedin,
    facebook: formData.facebook || formData.social_links?.facebook,
    twitter: formData.twitter || formData.social_links?.twitter,
    publications: formData.publications || [{ publication: '' }],
  };
  pageLoaded.value = true;
});
</script>

<style scoped>
.head {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #1b224b;
}

.tab-container {
  margin-top: 24px;
  margin-bottom: 40px;
}

input[type='checkbox'] {
  color: rgba(0, 0, 0, 0.2);
  margin-right: 14px;
}

.add-btn {
  width: fit-content;
}

.add {
  color: #e91868;
}
</style>
