<template>
  <div class="flex flex-col h-full">
    <div class="mb-4 border-b border-gray-200" :class="[hidden ? 'hidden md:block' : 'md:block']">
      <ul
        class="flex items-center -mb-px text-sm font-medium text-center overflow-x-scroll whitespace-nowrap scrollbar-hide snap-proximity snap-center"
        :class="[spread ? 'justify-between' : '']"
        role="tablist"
      >
        <li v-for="(step, i) in numberOfSteps" :key="i" class="">
          <button
            class="inline-block text-base p-4 rounded-t-lg cursor-default"
            type="button"
            @click="toggleTabs(i + 1)"
            :class="[currentStep !== i + 1 ? 'text-brand-black text-opacity-60' : 'text-brand-black font-bold border-b-2', borderColor]"
          >
            {{ step.name }}
          </button>
        </li>
      </ul>
    </div>
    <div :class="className" class="grow overflow-y-auto scrollbar-hide">
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
const emit = defineEmits(['changeTab', 'before-next-step', 'stepper-finished', 'completed-step', 'active-step']);

const props = defineProps({
  className: { type: String },
  numberOfSteps: { type: Array },
  currentStep: Number,
  borderColor: String,
  hidden: Boolean,
  spread: Boolean,
});

const toggleTabs = (tabNumber) => {
  emit('changeTab', tabNumber);
};
</script>
