<template>
  <form @submit.prevent="handleUpdate" class="mt-2">
    <div class="p-4 lg:p-0">
      <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">About the Office <is-required /></label>
      <textarea
        required
        id="about"
        v-model="details.about_office"
        rows="5"
        cols="33"
        placeholder="Start typing..."
        class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
      >
      </textarea>
      <div class="">
        <p class="text-right font-normal text-sm mt-1">Minimum of 80 characters</p>
      </div>
    </div>

    <div class="mt-4 p-4 lg:p-0">
      <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Office Location <is-required /></label>
      <input
        required
        v-model="details.location"
        type="text"
        id="input-group-1"
        class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
        placeholder="Type the location of the office"
      />
    </div>

    <div class="p-4 lg:p-0 mt-4">
      <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Company Cover Photo <is-required /></label>
      <div class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-6 sm:px-4 flex justify-between items-center">
        <label for="dropzone-file" class="block w-full text-center py-4 cursor-pointer">
          <img v-if="fileUrl" :src="fileUrl" class="mt- mb-4 h-40 w-40 object-cover mx-auto lg:mx-0" alt="companyBanner" />
          <div v-else>
            <img src="@/assets/icons/upload2.svg" class="mx-auto mb-2" alt="" />
            <div>
              <p>Tap to upload</p>
              <small>Recommended size: 400px x 300px | PNG, JPG | 1MB max.</small>
            </div>
          </div>
          <input ref="selectedFile" id="dropzone-file" type="file" class="hidden" accept=".jpg,.jpeg,.png,.svg" @change="previewImage" />
        </label>
      </div>
    </div>

    <div class="w-full mt-6 grid grid-cols-2 gap-4">
      <button
        type="button"
        @click="goBack"
        class="w-full border-brand-black border text-black bg-white p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ button_prev }}
      </button>
      <button
        :disabled="checkDisability"
        type="submit"
        class="w-full bg-brand-black text-white p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ loading ? 'Loading...' : button_action }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import IsRequired from '@/components/IsRequired.vue';
import CameraSvg from '@/assets/icons/camera.svg?inline';
import ChevronDownSvg from '@/assets/icons/chevron-down.svg?inline';

const store = useStore();
const props = defineProps({
  task: { type: String, default: 'onboard' },
});
const details = ref({
  about_office: '',
  location: '',
  banner: '',
});
const pageLoaded = ref(false);
const file = ref(null);
const fileUrl = ref(null);
const disabled = ref(false);
const loading = ref(false);
const banner = ref(null);

const button_action = computed(() => (props.task === 'onboard' ? 'Continue' : 'Save'));
const button_prev = computed(() => (props.task == 'onboard' ? 'Back' : ''));

const checkDisability = computed(() => {
  return details.value.about_office && details.value.location !== '' && fileUrl.value !== null ? (disabled.value = false) : (disabled.value = true);
});
const userForm = computed(async () => await store.getters['global/getForm']);

const emit = defineEmits(['changeTab', 'goPrev']);

const userDetails = computed(async () => await store.getters['auth/userDetails']);

const previewImage = (e) => {
  const image = e.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(image);
  if (image?.size > 5000000) {
    alert('file too big', 'error');
    return;
  }
  reader.onload = (e) => {
    fileUrl.value = e.target.result;
    file.value = image;
    // console.log(previewImage.value, file.value);
  };
  // if (e?.target?.files[0]) {
  //   let reader = new FileReader();
  //   reader.onload = (e) => {
  //     if (!e) return;
  //     fileUrl.value = e.target.result;
  //   };
  //   if (e?.target?.files[0]?.size > 5000000) {
  //     alert('file too big', 'error');
  //     return;
  //   }
  //   reader.readAsDataURL(e.target.files[0]);
  //   file.value = e.target.files[0];
  // }
};

async function updateAvatar() {
  try {
    const data = new FormData();
    if (file.value) {
      data.append('banner', file.value);
      await store.dispatch('auth/updateCompanyBanner', data);
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

const goBack = () => {
  emit('goPrev');
};

async function handleUpdate() {
  try {
    disabled.value = true;
    loading.value = true;

    updateAvatar();
    if (props.task == 'onboard') {
      await store.commit('global/setForm', { ...userForm.value, ...details.value });
      emit('changeTab');
    } else {
      const user = await store.getters['auth/userDetails'];
      const formData = { ...user.employer, ...details.value };
      const { message } = await store.dispatch('auth/updateUserProfile', formData);
      alert(message, 'success');
      store.dispatch('auth/getUser', { forceReload: true });
    }
    disabled.value = false;
    loading.value = false;
  } catch (error) {
    disabled.value = false;
    loading.value = false;
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

onMounted(async () => {
  const user = await store.getters['auth/userDetails'];
  const formData = { ...user.employer, ...userForm.value };
  details.value = {
    about_office: formData.about_office,
    location: formData.location,
    banner: formData.banner,
  };
  banner.value = banner.value ? banner.value : formData.banner;
  pageLoaded.value = true;
});
</script>

<style scoped>
.head {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #1b224b;
}

.tab-container {
  margin-top: 24px;
  margin-bottom: 40px;
}
</style>
