<template>
  <DashboardAuthLayout>
    <!-- BODY -->
    <div class="flex-grow flex justify-around">
      <div class="max-w-5xl w-full">
        <div class="p-8 bg-white w-full rounded-xl">
          <h1 class="text-brand-black text-[28px] font-semibold leading-[130%]">
            Set up {{ userDetails?.employer?.name || 'TekBiz' }} profile on CareerBuddy
          </h1>
          <div class="flex items-center mb-2">
            <span class="font-normal text-light-gray mr-3 text-[16px]">Complete your sign up by telling us a little about your company</span>
          </div>

          <!-- Tab here -->
          <div class="tab-container mt-2">
            <div class="rounded-lg w-full">
              <Stepper :numberOfSteps="tabs" :currentStep="tabNumber" borderColor="border-brand-black" spread>
                <div class="rounded-lg w-full">
                  <div v-if="tabNumber == 1" id="about" role="tabpanel" aria-labelledby="about-tab" class="grid mt-2">
                    <about-company task="onboard" @changeTab="changeTab(tabNumber + 1)" />
                  </div>
                  <div v-if="tabNumber == 2" id="location" role="tabpanel" aria-labelledby="location-tab" class="grid mt-2">
                    <company-location task="onboard" @changeTab="changeTab(tabNumber + 1)" @goPrev="changeTab(tabNumber - 1)" />
                  </div>
                  <div v-if="tabNumber == 3" id="social" role="tabpanel" aria-labelledby="social-tab" class="grid mt-2">
                    <social-media task="onboard" @changeTab="submitForm" @goPrev="changeTab(tabNumber - 1)" />
                  </div>
               <!-- <div v-if="tabNumber == 4" id="details" role="tabpanel" aria-labelledby="details-tab" class="grid mt-2">
                    <employee-details task="onboard" @changeTab="submitForm" @enableSubmit="enableSubmit" />
                  </div>  -->
                </div>
              </Stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardAuthLayout>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import DashboardAuthLayout from '@/layouts/AuthLayoutFull.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { errorMessage, alert } from '@/utils/helper';
import Tab from '@/components/Tab.vue';
import Stepper from '@/components/Stepper.vue';
import ButtonLoader from '@/components/ButtonLoader.vue';
import SocialMedia from '@/components/auth/SocialMedia.vue';
import AboutCompany from '@/components/auth/AboutCompany.vue';
import CompanyLocation from '@/components/auth/CompanyLocation.vue';
import EmployeeDetails from '@/components/auth/EmployeeDetails.vue';

const router = useRouter();
const store = useStore();
const loading = ref(false);
const disabled = ref({ 1: false });
const tabNumber = ref(Number(localStorage.getItem('profileTabNumber')) || 1);

const tabs = ref([
  {
    name: 'About the Company',
  },
  {
    name: 'Location',
  },
  {
    name: 'Social Media',
  },
  // {
  //   name: 'Your Details',
  // },
]);
const changeTab = (value) => {
  localStorage.setItem('profileTabNumber', value);
  tabNumber.value = value
  console.log(tabNumber.value, 'changed');
  window.scrollTo(0, 50);
};

const userDetails = computed(() => {
  return store.getters['auth/userDetails'];
});

const checkValidity = (val) => {};

const enableSubmit = (enable) => {
  disabled.value = enable;
};

async function submitForm() {
  try {
    loading.value = true;
    disabled.value = true;
    const form = await store.getters['global/getForm'];
    const { message } = await store.dispatch('auth/updateUserProfile', form);
    alert(message, 'success');
    router.push('/dashboard');
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  store.commit('global/setForm', null);
  // if (tabNumber.value.length > 1) return;
  // localStorage.setItem('tabNumber', 1);
  // console.log(tabNumber.value, "mount");
});
</script>

<style></style>
