<template>
  <form @submit.prevent="handleUpdate">
    <div class="p-4 lg:p-0">
      <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Company Description <is-required /></label>
      <textarea
        required
        id="description"
        v-model="details.bio"
        rows="5"
        cols="33"
        placeholder="Start typing..."
        class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
      >
      Start typing...
      </textarea>
      <div class="">
        <p class="text-right text-light-gray mt-1 leading-[140%] text-[13px]">Minimum of 80 characters</p>
      </div>
    </div>

    <div class="p-4 lg:p-0 flex space-x-2 md:space-x-4 mt-4">
      <div class="w-1/2">
        <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Year Founded <is-required /></label>
        <input
          required
          v-model="details.founded_at"
          type="date"
          id="founded_at"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
          placeholder="Please fill the year funded"
        />
      </div>

      <div class="w-1/2">
        <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Industry <is-required /></label>
        <select
          required
          v-model="details.company_category"
          id="role-type"
          name="role-type"
          autocomplete="role-type"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
        >
          <option disabled value="">Select One</option>
          <option class="" v-for="item in companyCategories" :key="item.key" :value="item.key">
            {{ item.value }}
          </option>
        </select>
      </div>
    </div>

    <div class="p-4 lg:p-0 flex space-x-2 md:space-x-4 mt-4">
      <div class="w-1/2">
        <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Company Size</label>
        <p></p>
        <select
          required
          v-model="details.company_size"
          id="role-type"
          name="role-type"
          autocomplete="role-type"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
        >
          <option disabled value="">Select an option</option>
          <option class="" v-for="(item, index) in companySize" :key="index" :value="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="w-1/2">
        <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Total Funding to date</label>
        <select
          v-model="details.funding"
          id="role-type"
          name="role-type"
          autocomplete="role-type"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
        >
          <option class="" v-for="(item, index) in totalFunding" :key="index" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
    </div>

    <div class="p-4 lg:p-0 mt-4">
      <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Company Logo <is-required /></label>
      <div class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-6 sm:px-4 flex justify-between items-center">
        <label for="dropzone-file" class="flex items-center cursor-pointer">
          <img :src="previewImage" class="rounded w-1/2 h-1/2" v-if="previewImage" />
          <div class="flex items-center" v-else>
            <img src="@/assets/icons/upload2.svg" class="mr-4" alt="" />
            <div>
              <p>Tap to upload</p>
              <small>Recommended size: 400px x 300px | PNG, JPG | 1MB max.</small>
            </div>
          </div>
          <input ref="selectedFile" id="dropzone-file" type="file" class="hidden" accept=".jpg,.jpeg,.png,.svg" @change="uploadImage" />
        </label>
        <label for="dropzone-file" class="bg-brand-black px-6 sm:px-4 py-3 text-white rounded-xl block cursor-pointer" @click="uploadImage">
          Upload
        </label>
      </div>
    </div>

    <!-- <div class="mt-3 p-4 rounded-md border-2 border-[#F7EDF5]">
      <div class="rounded-md border-2 border-dashed border-[#F7EDF5] flex flex-col py-5 items-center justify-around space-y-3">
        <span class="text-[#090E11]/70 font-normal text-sm">Select file to uplpad</span>
        <div class="">
          <img v-if="previewImage" :src="previewImage" class="mt- mb-4 h-40 w-40 object-cover mx-auto lg:mx-0" alt="companyLogo" />
          <label for="dropzone-file">
            <div class="py-3 px-4 space-x-2 flex items-center justify-between text-[#F0386A] border rounded border-[#F0386A] cursor-pointer">
              <CameraSvg />
              <span class="font-normal text-sm">Choose file</span>
              <ChevronDownSvg />
            </div>
            <input ref="selectedFile" id="dropzone-file" type="file" class="hidden" accept=".jpg,.jpeg,.png,.svg" @change="previewImage" />
          </label>
        </div>
        <span class="text-[#090E11]/70 font-normal text-sm">Supported format: JPG, PNG. File size limit is 10MB</span>
      </div>
    </div> -->
    <div class="w-full text-left mt-6">
      <button
        type="submit"
        :disabled="checkDisability"
        class="w-full bg-brand-black text-white p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ loading ? 'Loading...' : button_action }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import CameraSvg from '@/assets/icons/camera.svg?inline';
import ChevronDownSvg from '@/assets/icons/chevron-down.svg?inline';
import { errorMessage, alert } from '@/utils/helper';
import IsRequired from '@/components/IsRequired.vue';

const store = useStore();
const props = defineProps({
  task: { type: String, default: 'onboard' },
});
const emit = defineEmits(['changeTab', 'canGoNext']);
const details = ref({
  bio: '',
  founded_at: '',
  company_category: '',
  company_size: '',
  funding: '',
});
const pageLoaded = ref(false);
const companyCategories = ref(null);
const previewImage = ref(null);
const file = ref(null);
// const previewImage = ref(null);
const disabled = ref(false);
const loading = ref(false);
const companySize = ref(['1-5', '6-10', '11-50', '> 51']);
const totalFunding = ref([
  'below 100,000 USD',
  '$100k to $500k',
  '$500k to $1,00,000',
  '$1,000,000 to $5,000,000',
  '$5,000,000 to $10,000,000',
  '$10,000,000 and above',
]);

const checkDisability = computed(() => {
  return details.value.company_category && details.value.bio && previewImage.value !== null ? (disabled.value = false) : (disabled.value = true);
});

const button_action = computed(() => (props.task == 'onboard' ? 'Continue' : 'Save'));

const userForm = computed(async () => await store.getters['global/getForm']);

const userDetails = computed(async () => await store.getters['auth/userDetails']);

// const previewImage = (e) => {
//   if (e?.target.files[0]) {
//     let reader = new FileReader();
//     reader.onload = (e) => {
//       if (!e) return;
//       previewImage.value = e.target.result;
//     };
// if (e.target.files[0]?.size > 5000000) {
//   alert('file too big', 'error');
//   return;
// }
// reader.readAsDataURL(e.target.files[0]);
// file.value = e.target.files[0];
//   }
// };

const uploadImage = (e) => {
  const image = e.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(image);
  if (image?.size > 5000000) {
    alert('file too big', 'error');
    return;
  }
  reader.onload = (e) => {
    previewImage.value = e.target.result;
    file.value = image;
    // console.log(previewImage.value, file.value);
  };
};

async function updateAvatar() {
  try {
    const data = new FormData();
    if (file.value) {
      console.log('<<<<<<<<<<<<<<<===========', file.value);
      data.append('logo', file.value);
      console.log(data, '>>>>>>>>>>>>>>>>>>>>>>============');
      await store.dispatch('auth/updateEmployerAvatar', data);
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

async function handleUpdate() {
  try {
    disabled.value = true;
    loading.value = true;
    if (!file.value && !previewImage.value) {
      alert('please select company logo', 'info');
      loading.value = false;
      return;
    }
    updateAvatar();
    if (props.task === 'onboard') {
      await store.commit('global/setForm', { ...userForm.value, ...details.value });
      emit('changeTab');
    } else {
      const user = await store.getters['auth/userDetails'];
      const formData = { ...user.employer, ...details.value };
      const { message } = await store.dispatch('auth/updateUserProfile', formData);
      alert(message, 'success');
      store.dispatch('auth/getUser', { forceReload: true });
    }
    disabled.value = false;
    loading.value = false;
  } catch (error) {
    disabled.value = false;
    loading.value = false;
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

onMounted(async () => {
  companyCategories.value = await store.dispatch('global/getCompanyCategory');
  const user = await store.getters['auth/userDetails'];
  const formData = { ...user.employer, ...userForm.value };
  let date = '';
  if (formData.founded_at) {
    const d = new Date(formData.founded_at);
    const dd = d.getUTCDate() < 10 ? `0${d.getUTCDate()}` : d.getUTCDate();
    const mm = d.getUTCMonth() < 10 ? `0${d.getUTCMonth()}` : d.getUTCMonth();
    date = `${d.getUTCFullYear()}-${mm}-${dd}`;
  }

  details.value = {
    bio: formData.bio,
    founded_at: date,
    company_category: formData.company_category,
    company_size: formData.company_size,
    funding: formData.funding,
    website: formData.website,
  };
  previewImage.value = previewImage.value ? previewImage.value : formData.logo_url;
  pageLoaded.value = true;
});
</script>

<style scoped>
.head {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #1b224b;
}

.tab-container {
  margin-top: 24px;
  margin-bottom: 40px;
}
</style>
