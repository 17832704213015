<template>
  <div id="DashboardAuthLayout" class="min-h-screen" :style="{ backgroundImage: `url(${require('@/assets/images/2.png')})` }">
    <div class="fixed inset-0 bg-brand-black/[0.9] -z--1"></div>
    <div class="z-10 relative w-full flex items-center justify-center min-h-screen">
      <div class="px-4 w-full sm:max-w-md md:max-w-2xl flex flex-col justify-center pb-[4rem]">
        <img src="@/assets/images/logo-new.svg" class="w-[230px] mt-8 mb-6" alt="">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
